import { createGlobalStyle, css } from "styled-components";

/*
const Stack = (() => {
  const Divider = styled.div``;
  const Base = styled.div`
    > ${Divider} {
      height: ${({ space }) =>
        typeof space === "number" ? `${space}px` : space};
    }
  `;
  return ({ space, children }) => (
    <Base space={space}>
      {React.Children.toArray(children).map((v, k, self) => (
        <React.Fragment key={k}>
          <div>{v}</div>
          {k !== self.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Base>
  );
})();
*/

export const NormalizeCSS = createGlobalStyle`
html {
  box-sizing: border-box;
  font-size: 16px;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
ol, ul {
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
`;

export const breakpoints = breakpoints =>
  Object.keys(breakpoints).reduce((accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
    return accumulator;
  }, {});
